import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const tmpDoc = {
    "id": 1,
    "name": "ty1",
    "respond_case": "Thank you",
    "template_layout": "thnk you very much"
}

export default function TemplateCard(props) {
  var check = true;

  console.log(check)
  console.log(props.selectedTemp)
  return (
    <Card sx={{ minWidth: 275 ,minHeight: 420, m: 3, mb:2,mt:12, pt:2}}>
      <CardContent>
        <Typography m={5} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Template : {check ? props.selectedTemp['templates.respond_case']:'Response Case'} 
        </Typography>
        <Typography sx={{ m: 2 }} variant="h5" component="div" m={2}>
          {check ? props.selectedTemp['templates.name']:'Please Select Template from List'} 
        </Typography> 

        <Typography sx={{ m: 2 }} variant="body" m={2}>
          {check ? props.selectedTemp['templates.template_layout']:''}
        </Typography>
        
      </CardContent> 

    </Card>
  );
}
