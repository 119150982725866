
import './App.css';

import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";

import { Provider } from "react-redux";
//import store from "./store";


//import Landing from "./components/layout/Landing"; // no longer in use
import Register from "./components/auth/Register"; 
import Login from "./components/auth/Login";   
import UserPage from "./components/dashboard/userpage";  

import AddEmail from "./components/dashboard/addemail";
import InformationPage from "./components/dashboard/informationpage";
import InformationTabs from "./components/dashboard/informationTabs";
import UserRules from "./components/dashboard/userrulespage";
import TemplatesPage from "./components/dashboard/templatespage";  
import UserSchedule from "./components/dashboard/userSchedule";  

import ResetForm from "./components/auth/ResetForm";
import PasswordReset from "./components/auth/PasswordReset";


function App() {
  return (
    <div className="App">
     
          <Router>
            <div >
              
              <Route exact path="/" component={Login} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/Register" component={Register} />  
              <Route exact path="/UserPage" component={UserPage} />

              <Route exact path="/ResetForm" component={ResetForm} /> 
              <Route exact path="/PasswordReset" component={PasswordReset} /> 

              <Route exact path="/AddEmail" component={AddEmail} />
              <Route exact path="/InformationPage" component={InformationTabs} />
              <Route exact path="/UserRules" component={UserRules} />  
              <Route exact path="/TemplatesPage" component={TemplatesPage} />   
              <Route exact path="/UserSchedule" component={UserSchedule} />  

            </div>
          </Router>
    </div>
  );
}

export default App;
