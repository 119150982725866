import * as React from 'react';
import { useState, useEffect, useContext, createContext } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory,useLocation } from "react-router-dom";
import Link from '@mui/material/Link';

import { FacebookLoginButton, GoogleLoginButton  } from "react-social-login-buttons";

import { useCallback } from 'react'
import Navbar from "../layout/Navbar"; 

import axios from 'axios';
import colorThm from "../colorTheme";
import StyledInput from '../customTextField';

const theme = createTheme(); 
//registration for users
// after blockchain signup works remove the extra buttons for navigating directly; or add dummy accounts for now
export default function SignIn() {
  let history = useHistory();
  const location = useLocation();
  React.useEffect(() => { // use effect : on render, get all service providers 

    console.log('get file ');
    const search = location.search;
    const token = new URLSearchParams(search).get('refresh_token');
    console.log(search);
    console.log(token);

    if( search && token){

      var bodyFormData = new FormData();
      bodyFormData.append('refresh_token', token); 

      // run axios call for axios and fwd to userpage
      axios({
        method: "post",
        url: "https://evemail.fiyge.com/access_controls/users/access_token.json",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(res => {
          console.log(res);
          console.log(res.data);
          //PHPSESSID=sbsqqpildvnccpfkgpji7c6l2i
          const message = res.data.message; // chcek len of errors
          console.log(res.data.access_token);
   
          //console.log(accesstoken); 
          window.sessionStorage.setItem('userUID',res.data.access_token );
          window.sessionStorage.setItem('user_fullname',res.data.user_fullname );
          history.push("/UserPage",{ accesstoken:res.data.access_token });
    
      });
    }
    
  }, []);

  // function when submit is pressed
  const handleSubmit = (event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //const auth = getAuth()
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    var bodyFormData = new FormData();
    //'admin@evemail.fiyge.com'
    bodyFormData.append('data[users][user_name]',      data.get('email') );
    bodyFormData.append('data[users][user_password]',  data.get('password'));

    var accesstoken = ''

    //axios.post(`https://evemail.fiyge.com/access_controls/users/login.json`, { user })
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/access_controls/users/login.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
        console.log(res);
        console.log(res.data);
        //PHPSESSID=sbsqqpildvnccpfkgpji7c6l2i
        const message = res.data.message; // chcek len of errors
        if(res.data.errors.length == 0 ){
          accesstoken = res.data.access_token;
      
          //console.log(accesstoken); 
          window.sessionStorage.setItem('userUID',accesstoken);
          window.sessionStorage.setItem('user_fullname',res.data.user_fullname );
          history.push("/UserPage",{ accesstoken:accesstoken });
        }
    });

      
  };
 
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: colorThm.colors.primary }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <StyledInput  
              fullWidth
              variant="filled"
              margin="normal"
              required
              
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <StyledInput   
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Sign In
            </Button>

            <Typography
              component="h4"
              variant="h4"
              align="center"
              color="text.primary"   
              sx={{ mt: 1, mb: 2 }}
            >
              {
                // style = {{borderRadius: 50, backgroundColor:'White', width: '80px',height: '80px'}}
              }
              OR
            </Typography>
            
            <Grid container>
              <Grid item xs={12}>
                <Link href="https://evemail.fiyge.com/access_controls/users/social_login?provider=Google">
                  <GoogleLoginButton >
                    <span> Sign In with Google</span>
                  </GoogleLoginButton >
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="https://evemail.fiyge.com/access_controls/users/social_login?provider=Facebook">
                  <FacebookLoginButton >
                    <span>  Sign In with Facebook </span>
                  </FacebookLoginButton>
                </Link>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs>
                <Link href="/ResetForm" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/Register" variant="body2">
                  <b>
                  {"Don't have an account? Sign Up"}
                  </b>
                  
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box> 
        
      </Container>
    </ThemeProvider>
  );
}
/*

<Button 
              fullWidth
              href = "https://evemail.fiyge.com/access_controls/users/social_login?provider=Google"
              variant="contained" 
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Google Sign In
            </Button>
            <Button 
              fullWidth
              href = "https://evemail.fiyge.com/access_controls/users/social_login?provider=Facebook" 
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Facebook Sign In 
            </Button> 
<Link to="/Patient">
          <Button 
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
          >
              Sign In Pat 
          </Button>
        </Link>
          <Link to="/Doctor">
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In Doctor 
              
            </Button>
            </Link>
*/