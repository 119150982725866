import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import colorThm from "../colorTheme";

// navigation bar for the public features 
function Heading() {
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{ background: '#000000' }} 
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        
        <Toolbar sx={{ flexWrap: 'wrap' }}> 
          <img src="/brand_logo.png"  width="7%" height="7%" style={{padding: 5}}  />

          <Link href="/" color="inherit" noWrap sx={{ flexGrow: 1,textDecoration: 'none'  }}>   
            <Typography variant="h4" align="left" color="#fff" noWrap sx={{ flexGrow: 1 , my: 1, mx: 1.5}}>
            
              EveMail
            </Typography>
          </Link>
 
          
          
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default function Header() {
  return <Heading />;
}


/*
<Button  href="/Login" variant="contained" sx={{ my: 1, mx: 1.5 }} style = {{backgroundColor: colorThm.colors.primary}} >
            Login
          </Button>
*/