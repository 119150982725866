import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { alpha, styled } from '@mui/material/styles';
import { useState, useEffect  } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";

import DashNavbar from "./DashNavbar";
import EmailList from "./emailList";

import ControlPointIcon from '@mui/icons-material/ControlPoint';

import colorThm from "../colorTheme";
import axios from 'axios';
import CustomButton from '../customBtn'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-input': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});

const dummyEmails = [
  {"email": "a@gmail.com"},{"email": "b@gmail.com"},{"email": "c@gmail.com"}
]

// api.evemail.one
//'https://172.105.25.67:5006/ReadEmailsPredict'//
const FlaskAPI = 'https://api.evemail.one/ReadEmailsPredict'//'http://172.105.25.67:5006/ReadEmailsPredict'//'https://api.evemail.one/ReadEmailsPredict'//'http://172.105.25.67:5006/ReadEmailsPredict'
 
//"http://192.168.1.128:5006/ReadEmailsPredict"
//"https://api.evemail.one/ReadEmailsPredict"
//"http://192.168.1.128:5006/ReadEmailsPredict"

function runBot(emailData){

  // run axios call for axios and fwd to userpage
  console.log(emailData); 
  //console.log(emailData['accounts.email']); 
  //console.log(emailData['accounts.password']); 
  //console.log(emailData['Service Provider']); // Yandex

  axios.post(FlaskAPI, 
    
    {
    "email": emailData['accounts.email'],
    "password": emailData['accounts.password'],
    "type": emailData['Service Provider'],
    "account_id": emailData['accounts.id'],
    "access": window.sessionStorage.getItem('userUID')
  })
  .then(res => {
    console.log(res)
    return res;
  }); 
}
//  accounts page 
export default function UserPage(props) {
 /// var userData = props.history.location.state.userData;
  //console.log(userData)
  let history = useHistory(); 
  const [accounts, setaccounts] = React.useState([]);

  useEffect(() => { // pull in all user accounts and set the access token from signing in 

    //var token = ''
    if(window.sessionStorage.getItem('userUID') === null){
      window.sessionStorage.setItem('userUID',  props.history.location.state ? props.history.location.state.accesstoken : "");
    }
   
    
    //downloadFile("/Medical Report Form Sample.pdf",setpdfurl); 
    //https://firebasestorage.googleapis.com/v0/b/blocksure-8c41f.appspot.com/o/Medical%20Report%20Form%20Sample.pdf?alt=media&token=11649600-6654-4557-950e-505f1b06b471
    
    //var bodyFormData = new FormData();
    //bodyFormData.append('data[users][user_name]', 'admin@evemail.fiyge.com');
    //bodyFormData.append('data[users][user_password]', 'admin@evemail.fiyge.com');
    //data: bodyFormData,

    //console.log(window.sessionStorage.getItem('userUID'))
    //console.log(props.history.location.state.accesstoken)

    //const token = props.history.location.state.accesstoken ? props.history.location.state.accesstoken : window.localStorage.getItem('accesstoken');
 
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/accounts/index.json",
        
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' +  window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log("service_providers");
        console.log(res);
        if(res.data.paginate){
          console.log(res.data.paginate.data);
          setaccounts(res.data.paginate.data);
        }
        
    }); 
  }, []);
  

 // display list of email accounts and add new email button
 
  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "EveMail" to = "/UserPage" />
      <main>
        {/* 
          buttons for the various options along with some header text 
           bgcolor: '#f5f5f5',
        */} 
        <Box
          sx={{
           
            pt: 8,
            pb: 6
          }}
        >
          
          <Container  >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Welcome, {window.sessionStorage.getItem('user_fullname')}
            </Typography> 
            <Grid container spacing={3} > 
              
              <Grid item xs={12}>
               
                <Grid item xs={12}>
                  <Typography variant="h4" align="center" color="text.primary" paragraph>
                      Accounts 
                  </Typography>
                  
                </Grid> 
                <Grid item xs={2}>
                  <CustomButton 
                    variant="contained"   

                    startIcon={<ControlPointIcon />}  
                    onClick={ () => history.push("/AddEmail",{accounts} ) } 
                    style = {{backgroundColor: colorThm.colors.primary}}
                  >
                    Add New 
                  </CustomButton>
                </Grid> 
                <Grid item xs={11}>
                  <Typography variant="h8" align="center" color="text.primary" paragraph>
                    Click on a account to see its options
                  </Typography>
                </Grid>        
                
              </Grid>
              <Grid item xs={12}>
                <span key={accounts}>{
                  <EmailList data={accounts} runBot={runBot} />
                }</span>
              </Grid> 
            </Grid>

            
          </Container>
        </Box> 
      </main>
      
    </div>
  );
}

//direction="row"
/*
 
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Keep up to date with your medical history 
                </Typography>
*/