import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import DashNavbar from "./DashNavbar";
import ListDetails from './listdetails';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import colorThm from "../colorTheme";
import CustomButton from '../customBtn';
import StyledInput from '../customTextField';

import { useHistory } from "react-router-dom"; 


import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect  } from 'react';

import APIpath from "../apiPath";
import { DocList, ProductList,DatesGrid,BasicEditingGrid } from './listdetails';

const axios = require('axios');

// component for the form so multiple can be generated
// next step 
// add in the handle CustomButton and pass in the user id, update color and take data from parent component
export default function RequestForm(props) {
  let history = useHistory();
  const report = props.props.report;

  const [docName, setdocName] = React.useState( ('')); // text filed vraibles 
  const [productName, setproductName] = React.useState( (''));
 
  const [docLink, setdocLink] = React.useState( ('')); // text filed vraibles 
  const [product, setproduct] = React.useState( (''));
  const [mission, setmission] = React.useState( (''));

  const [docs, setdocs] = React.useState( [] );
  const [products, setproducts] = React.useState( [] ); // for lists 
    
  useEffect(() => { // pull all faq releated to user and organize 
   
      console.log('get file ');
      console.log( props.props.location.state.items );
      var bodyFormData = new FormData();
      // how to query something other than the id ?
      bodyFormData.append('data[accounts][id]', props.props.location.state.items['accounts.id']);
      
      var query={};
      query['where']=[{"faqs.account_id":props.props.location.state.items['accounts.id']}];
      console.log(query);
      console.log(encodeURIComponent(JSON.stringify(query)));
      axios({
        method: "post",
        url: "https://evemail.fiyge.com/evemail/faqs/index.json?q="+encodeURIComponent(JSON.stringify(query)),
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => {
          console.log(res);
          console.log(res.data); 

          if(res.data.paginate){
            console.log(res.data.paginate.data); 
            //console.log(res.data.paginate.data.filter( (value, index, self) =>(self.value['faqs.content_type']) === 'document')); 
 
            setdocs(res.data.paginate.data.filter( (value) =>  ((value['faqs.content_type']) === 'document') ));
 
            setproducts(res.data.paginate.data.filter( (value) =>  ((value['faqs.content_type']) === 'Product') ));
          }  
      });
      
  }, [docs,products]);
  
  const handleSubmitDoc = () => { // submit doc link add to current list and add to db (implement) : faq model 
    if(docLink != ""){  
      console.log('docs');
      console.log(docLink);
      // pull mission,docs,products
      // data: bodyFormData 
      var bodyFormData = new FormData();
      // how to query something other than the id 
      bodyFormData.append('data[faqs][account_id]', props.props.location.state.items['accounts.id']); 
      bodyFormData.append('data[faqs][question]', docName );
      bodyFormData.append('data[faqs][content]', docLink );
      bodyFormData.append('data[faqs][content_type]', "document" ); 

      axios({
        method: "post",
        url: "https://evemail.fiyge.com/evemail/faqs/add.json",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => {
          console.log(res);
          console.log(res.data); 

          if(res.data.paginate){
            console.log(res.data.paginate.data);
            setdocs([...docs, 
              {"faqs.account_id": props.props.location.state.items['accounts.id'],
              "faqs.content": docLink,
              "faqs.content_type": "document",
              'faqs.question': docName}
          ]);
          
          }
      });

      setdocName('')
      setdocLink('')
      }
  };

  const handleSubmitProduct = () => { // submit products link add to current list and add to db (implement) : faq mode
       
      if(product != ""){  
        console.log('product');
        console.log(product);
        // pull mission,docs,products
        // data: bodyFormData 
        var bodyFormData = new FormData();
        // how to query something other than the id 
        bodyFormData.append('data[faqs][account_id]', props.props.location.state.items['accounts.id']); 
        bodyFormData.append('data[faqs][question]', productName );
        bodyFormData.append('data[faqs][content]', product );
        bodyFormData.append('data[faqs][content_type]', "Product" ); 
 
        axios({
          method: "post",
          url: "https://evemail.fiyge.com/evemail/faqs/add.json",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
        }).then(res => {
            console.log(res);
            console.log(res.data); 
  
            if(res.data.paginate){
              console.log(res.data.paginate.data);
               
              setproducts([...products, {
                "faqs.account_id": props.props.location.state.items['accounts.id'],
                "faqs.content": product,
                "faqs.content_type": "Product",
                'faqs.question': productName}
              ]);
            }
        });
  
        setproductName('')
        setproduct('')
        }
    
  };
  //console.log(props.history.location.state.accounts);
  ///console.log(email_services) 
 
  // top is the dates table : figure out storage, and ui issue
  // then mission followed by documents and prodcuts 
  return ( 
    <div> 

        <Box sx={{padding: 5,m: 4, p: 4}}> 

          <Container  >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Account Information Records
            </Typography>

            
            <Grid container alignItems="center" justifyContent="center" spacing={3}> 
            <Grid item xs={4}>
              <StyledInput  
                fullWidth
                variant="filled"
                name="documents name"
                label="Document name" 
                value= {docName}
                onChange={(p) => {setdocName(p.target.value)} }  
              />
            </Grid> 
              
              <Grid item xs={6}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="Document Links"
                  label="Documents link"  
                  value= {docLink} 
                  onChange={(p) => {setdocLink(p.target.value)} }  
                />
              </Grid>
              <Grid item xs={2}> 
                <CustomButton variant="contained" onClick={ () => handleSubmitDoc() } style = {{backgroundColor: colorThm.colors.primary}} >
                  Submit Documents
                </CustomButton>
              </Grid>

              <Grid item xs={11}>
                {console.log(docs)}
                {
                  console.log(docs) 
                }
                
                <span key={docs}>
                  <DocList docs={docs} />
                  
                </span>
              </Grid>

               

              <Grid item xs={4}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="product name"
                  label="Product name" 
                  value= {productName}
                  onChange={(p) => {setproductName(p.target.value)} }  
                />
              </Grid>

              <Grid item xs={6}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="product description"
                  label="Product description" 
                  value= {product}
                  onChange={(p) => {setproduct(p.target.value)} }  
                />
              </Grid>
              <Grid item xs={2}>
                <CustomButton variant="contained" onClick={ () => handleSubmitProduct() } style = {{backgroundColor: colorThm.colors.primary}} >
                  Submit Product
                </CustomButton>
              </Grid>
              <Grid item xs={11}>
                <span key ={products}>
                  {console.log(products)}
                  <ProductList products={products} />
                </span>
              </Grid> 
    
            </Grid>
          </Container>
        </Box>

        

    </div>
  );
}

