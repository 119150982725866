import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import DashNavbar from "./DashNavbar";
import ListDetails from './listdetails';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import colorThm from "../colorTheme";
import CustomButton from '../customBtn';
import StyledInput from '../customTextField';

import { useHistory } from "react-router-dom"; 


import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect  } from 'react';

import APIpath from "../apiPath";
import {DataGridForWeek,DatesGrid,BasicEditingGrid } from './listdetails';
import { DataGrid } from '@mui/x-data-grid';   

const axios = require('axios');

// component for the form so multiple can be generated
// next step 
// add in the handle CustomButton and pass in the user id, update color and take data from parent component
export default function InformationPage(props) {
  let history = useHistory();
  const report = props.report;
 
  const [docLink, setdocLink] = React.useState( ('')); // text filed vraibles 
  const [product, setproduct] = React.useState( (''));
  const [mission, setmission] = React.useState( ('') );

  const [missionRecord, setmissionRecord] = React.useState( {} );  // for lists 
  const [WorkingHours, setWorkingHours] = React.useState( 
     
   [
    {
      id: 0,
      day: "Monday",
      time: "9to5", 
    },
    {
      id: 1,
      day: "Tuesday",
      time: "9to5",  
    },{
      id: 2,
      day: "Wednesday",
      time: "9to5",  
    },
    {
      id: 3,
      day: "Thursday",
      time: "9to5",  
    },{
      id: 4,
      day: "Friday",
      time: "9to5",  
    },
    {
      id: 5,
      day: "Saturday",
      time: "9to5",  
    },
    {
      id: 6,
      day: "Sunday",
      time: "9to5",  
    }
  ] ); // for WorkingHours 
  

  function updateRows(model){
    //setEditRowsModel(model);
    //console.log('model');
    //console.log(model); 
    //props.updateRows(model);
    console.log( Object.keys(model) );

    if( Object.keys(model).length > 0 ){

      
      const id = Object.keys(model)[0];
      console.log( id )
      console.log(Object.keys(model).length)
      console.log(model[id].time.value)

      //https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
      // 1. Make a shallow copy of the items
      let items = [...WorkingHours];
      console.log(items);
      // 2. Make a shallow copy of the item you want to mutate
      let item = {...WorkingHours[id]};
      // 3. Replace the property you're intested in
      item.time = model[id].time.value;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      items[id] = item;
      // 5. Set the state to our new copy
      //setState({items});
      console.log(items);
      //setRows(items)
      setWorkingHours(items); 
    }

  };

  function SendUpdates(){    
    // update mission statement 

    console.log("send updates")

    var bodyFormData = new FormData(); 
    

    bodyFormData.append('data[faqs][question]', 'Mission Statement');
    bodyFormData.append('data[faqs][content]', mission);
    bodyFormData.append('data[faqs][content_type]', "Mission"); 
    bodyFormData.append('data[faqs][account_id]', props.props.location.state.items['accounts.id']);   
    if( missionRecord['faqs.id'] ){
      bodyFormData.append('data[faqs][id]', missionRecord['faqs.id']  ); 
    }
      

    //update mission
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/faqs/add.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(
      res => {
        if(res.data.paginate){
          console.log(res.data.paginate.data); 
           
        }
        
      }
    )

    // update data  
    WorkingHours.map((record)  => {

      console.log(record);

      var workhoursData = new FormData();

      workhoursData.append('data[faqs][question]', record.day);
      workhoursData.append('data[faqs][content]', record.time);
      workhoursData.append('data[faqs][content_type]', "WorkingHours"); 
      workhoursData.append('data[faqs][account_id]', props.props.location.state.items['accounts.id']);   
      //workhoursData.append('data[faqs][id]', missionRecord['faqs.id']  ); 
      if( record['faqs.id'] ){
        bodyFormData.append('data[faqs][id]', record['faqs.id']  ); 
      }
      console.log('send add');
      axios({
        method: "post",
        url: "https://evemail.fiyge.com/evemail/faqs/add.json",
        data: workhoursData,
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => { 
          if(res.data.paginate){
            
            console.log('hours');
            console.log(res.data.paginate.data);  
          }
          
        });

    })


  };
  
  // update account pulled ; hardcoded atm
  useEffect(() => { // pull all faq releated to user and organize 

      console.log('get file ');
      console.log( props );
      var bodyFormData = new FormData();
      // how to query something other than the id ?
      bodyFormData.append('data[accounts][id]', props.props.location.state.items['accounts.id']);
       
      // pull mission,docs,products 
      var query={};
      query["where"] = [{"faqs.content_type":"Mission"},{"faqs.account_id":props.props.location.state.items['accounts.id']} ]; //, {"account_id":props.location.state.items['accounts.id']}
      const queryURI = encodeURIComponent(JSON.stringify( query ));     
      axios({
        method: "get",
        url: "https://evemail.fiyge.com/evemail/faqs/index.json?q="+queryURI,
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => {
          console.log(res);
          console.log(res.data); 

          if(res.data.paginate){
            console.log(res.data.paginate.data);
            console.log(res.data.paginate.data['faqs.content'])
            if(res.data.paginate.data.length > 0){
              setmission(res.data.paginate.data[0]['faqs.content']) 
              setmissionRecord(res.data.paginate.data[0])
            }
            

          }   
              //docLink, setdocLink product, setproduct mission, setmission 
   
          //console.log(accesstoken); 
          //history.push("/UserPage",{ accesstoken:accesstoken });
    
      });

      var query={};
      query["where"] = [{"faqs.content_type":"WorkingHours"},{"faqs.account_id":props.props.location.state.items['accounts.id']} ]; //, {"account_id":props.location.state.items['accounts.id']}
      const hrqueryURI = encodeURIComponent(JSON.stringify( query ));
      let workinghrs = [];     
      axios({
        method: "get",
        url: "https://evemail.fiyge.com/evemail/faqs/index.json?q="+hrqueryURI,
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => {
          console.log(res);
          console.log(res.data); 

          if(res.data.paginate){
            console.log(res.data.paginate.data); 
            if(res.data.paginate.data.length > 0){
              //reformat data and save over other 
              res.data.paginate.data.map( record => {
                if(record['faqs.content'] != ""  && 
                    record['faqs.question'] != "" && 
                    record['faqs.id'] != ""  ){

                  console.log(res.data.paginate.data.indexOf(record))
                  workinghrs.push({
                    'id':  res.data.paginate.data.indexOf(record), 
                    'faqs.id': record['faqs.id'],
                    'day': record['faqs.question'],
                    'time':record['faqs.content']
                  }) 
                } 
              })

              console.log(workinghrs)
              setWorkingHours(workinghrs)

            }
            

          }   
              //docLink, setdocLink product, setproduct mission, setmission 
   
          //console.log(accesstoken); 
          //history.push("/UserPage",{ accesstoken:accesstoken });
    
      });

 
      
  }, []);
 
  //console.log(props.history.location.state.accounts);
  ///console.log(email_services) 

  // top is the dates table : figure out storage, and ui issue
  // then mission followed by documents and products 

  const WorkColumns = [
    { field: 'day', headerName: 'Day', width: 200, headerAlign: 'center' },
    { field: 'time', headerName: 'Working Time', width: 255,  headerAlign: 'center', editable: true }
  ];

  return ( 
    <div>
        <Box sx={{padding: 5,m: 4, p: 4}}> 

          <Container  >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Account Information 
            </Typography>

            <Grid container alignItems="center" justifyContent="center" spacing={3}> 
              <Grid item xs={12}>
                <Typography
                  component="h5"
                  variant="h5"
                  align="left"
                  color="text.primary"
                  gutterBottom
                >
                  Working Hours
                </Typography> 
                
              </Grid> 
   
              <Grid item   xs={6}> 
                <div style={{ height: 500 , width: "100%"}}>
                  <Card sx={{ minHeight: 500,  m: 2}}>
                    <CardContent>
                    <DataGrid hideFooterPagination 
                      rows={WorkingHours} 
                      columns={WorkColumns} 
                      onEditRowsModelChange={updateRows} 
                      sx={{ 
                        backgroundColor:"#fff",
                        color: '#1a3e72',
                      }}
                      disableSelectionOnClick
                      />
                      </CardContent> 

                  </Card>
                </div>
              </Grid>    
              
              <Grid item xs={6}> 
                <StyledInput 
                  fullWidth
                  variant="filled"
                  name="Mission Statement"
                  label="Mission Statement" 
                  multiline
                  rows={7}
                  value= {mission }
                  onChange={(p) => {setmission(p.target.value)} } 
                />
              </Grid> 
              
              
              <Grid item xs={12}>
                <CustomButton onClick = {() => {SendUpdates()}} variant="contained" style = {{backgroundColor: colorThm.colors.primary}}  >
                  Submit Updates 
                </CustomButton>
              </Grid>
    
            </Grid>
          </Container>
        </Box>

        

    </div>
  );
}

/*
<Grid item   xs={6}> 
                <BasicEditingGrid WorkingHours={WorkingHours} updateRows={updateRows} />
              </Grid> 
*/
