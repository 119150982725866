import colorThm from "./colorTheme";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledInput = styled(TextField)`
  > div {
    background-color: #fff !important;
    border-radius: 8px;
    border: none !important;
    :before {
      border: none !important;
    }
    :after {
      border: none !important;
    }
    :hover {
      background-color: #fff;
    }
    > input {
      outline: none !important;
      border: none !important; 
    }
  }
`;
 
export default StyledInput;
