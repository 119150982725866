import * as React from 'react';
import { useState, useEffect, useContext, createContext } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory } from "react-router-dom";
import Link from '@mui/material/Link';

import { FacebookLoginButton, GoogleLoginButton  } from "react-social-login-buttons";

import { useCallback } from 'react'
import Navbar from "../layout/Navbar"; 

import axios from 'axios';
import colorThm from "../colorTheme";
import StyledInput from '../customTextField';

const theme = createTheme(); 
//registration for users
// after blockchain signup works remove the extra buttons for navigating directly; or add dummy accounts for now
export default function SignIn() {
  let history = useHistory();

  const [error,setError] = React.useState(''); 
  // function when submit is pressed
  const handleSubmit = (event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //const auth = getAuth()
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    var bodyFormData = new FormData();
    //'admin@evemail.fiyge.com'
    bodyFormData.append('data[users][user_name]',     data.get('email') );
    bodyFormData.append('data[users][callback_url]',  "https://app.evemail.one/PasswordReset");

    console.log(data.get('email'));

    var accesstoken = ''

    //axios.post(`https://evemail.fiyge.com/access_controls/users/login.json`, { user })
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/access_controls/users/activate_forgotten_password.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
        console.log(res);
        console.log(res.data);
        //PHPSESSID=sbsqqpildvnccpfkgpji7c6l2i
        const message = res.data.message; // chcek len of errors
        if(res.data.errors.length == 0 ){
          history.push("/");
          setError('');
        }else{
          setError("This user name does not exists");
        }
    });

      
  };
 
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           
          <Typography component="h1" variant="h5">
            Reset Account Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <StyledInput  
              fullWidth
              variant="filled"
              margin="normal"
              required
              
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />

            
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Submit Password Reset
            </Button> 

          </Box>
            <Typography
              component="h6"
              variant="h6"
              align="center"
              color="red"   
              sx={{ mt: 1, mb: 2 }}
            >
              {
                // style = {{borderRadius: 50, backgroundColor:'White', width: '80px',height: '80px'}}
              }
              {error}
            </Typography>
        </Box> 
        
      </Container>
    </ThemeProvider>
  );
}
/*

<Button 
              fullWidth
              href = "https://evemail.fiyge.com/access_controls/users/social_login?provider=Google"
              variant="contained" 
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Google Sign In
            </Button>
            <Button 
              fullWidth
              href = "https://evemail.fiyge.com/access_controls/users/social_login?provider=Facebook" 
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Facebook Sign In 
            </Button> 
<Link to="/Patient">
          <Button 
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
          >
              Sign In Pat 
          </Button>
        </Link>
          <Link to="/Doctor">
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In Doctor 
              
            </Button>
            </Link>
*/