import colorThm from "./colorTheme";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: colorThm.colors.primary,
  fontSize: "16px", 
  
  margin: "3px",
  borderRadius:5
}));

export default CustomButton;