import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { makeStyles } from "@material-ui/core/styles";

import ReactDOM from "react-dom";
import Input from "@material-ui/core/Input"; 
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
//for list 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';

//custom grid custom 
import Grid from '@mui/material/Grid'; 
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

/// data grid 
import { DataGrid } from '@mui/x-data-grid'; 
import StyledInput from '../customTextField';

import Delete from '@material-ui/icons/Delete';
import colorThm from "../colorTheme"; 

import Typography from '@mui/material/Typography';

// function to create content, used for faqs
function createData(content) {
  return { content };
}
// function to create content for working hours list
function createHrsData(day,time) {
  return { id: day.replace(" ", "_"),day,time };
}

// function to create data for rules
function createRules(Action,Content,Result) {
  return { Action,Content,Result };
}


const rows = [
  createData('Frozen yoghurt')
];

// dummy data for rules 
const rules = [
  createRules('fwd','sales','x@com'),
  createRules('fwd','tech','tech@com')
];

// list user emails in email accounts page  
export default function ListDetails(props) {
  //const data = [
  //  createData('Frozen yoghurt')
  //];

  const RowSelected = (row) =>{ // function to go to the next page and pass the user data
    // row click is passed as props
    console.log(row);
    // set email selected and edit button 
    props.handleEmailSelect(row)

  }

  //console.log(props)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Emails</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {props.userData.map((data) => (
            <TableRow
              key={data['accounts.id']}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => RowSelected(data) }
            >
              <TableCell component="th" scope="row">
                {data['accounts.email']}
              </TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// list documents in faq page : createdata used
export function DocList(props) {
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Document Name</TableCell> 
            <TableCell>Documents</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {props.docs.map((row) => (
            <TableRow
              key={row}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                
                {row['faqs.question']}
              </TableCell>
              <TableCell component="th" scope="row">
                
                {row['faqs.content']}
              </TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
// list product in product page  : createdata used
export function ProductList(props) {
  console.log(props)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          
          <TableRow> 
            <TableCell>Product Name</TableCell> 
            <TableCell>Product Description</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {props.products.map((row) => (
            
            <TableRow
              key={row}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            > 
              <TableCell component="th" scope="row">
                {row['faqs.question']}
              </TableCell>
              <TableCell  >{row['faqs.content']}</TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
// list rules in user rules page : createRules used
export function RulesList(props) {
  //console.log(rules)
  //console.log(props.accountRules)
  return (
    <TableContainer component={Paper}>
      <Table  stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow> 
            <TableCell>Action</TableCell>
            <TableCell>Content</TableCell> 
            <TableCell>Result</TableCell>  
            <TableCell> </TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {props.accountRules.map((row) => (
            
            <TableRow
              key={row.content}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >   
              <TableCell >{row['rule name']}</TableCell>
              <TableCell >{row['account_rules.trigger_condition']}</TableCell> 
              <TableCell >{row['account_rules.result']}</TableCell> 
              {
                //console.log(row)
              }
              <TableCell >
                <Delete color={colorThm.colors.primary} onClick={() => { props.deleteRule(row) }}/>
              </TableCell>  
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// list for templates 
export function TemplatesList(props) { 
  //console.log(props)
  return (
    <TableContainer component={Paper}>
      <Table  stickyHeader sx={{ minWidth: 450}} aria-label="simple table">
        <TableHead>
          <TableRow> 
            
            <TableCell>Response Case</TableCell> 
            <TableCell>Template Name</TableCell>
            <TableCell>Content</TableCell>  
            <TableCell> </TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {props.templateDetails.map((row) => (
            
            <TableRow
              key={row.content}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >  
              <TableCell >{row['account_rules.trigger_condition']}</TableCell> 
              <TableCell >{row['template name']}</TableCell> 
              <TableCell >{row['account_rules.result']}</TableCell>  
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
 

export function SubheaderList(props) {
  //console.log(props)
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 250,
        minWidth: 200,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 420,
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      {
        //<ListItemText alignItems={'flex-start'} >{`${header}`}</ListItemText>
      }
      {props.headers.map((header) => (
        <li key={`section-${header}`}>
          <ul>
            
            <ListItemText primary={<Typography align="left" variant="h6" sx={{ m: 2 }} style={{  color: 'black' }}>{header}</Typography>} />
            {

              props.templates.filter(

                function (x) {
                  return x['templates.respond_case'] == header;
                }
              ).map((item) => (
                <ListItem alignItems={'flex-end'}  key={item['templates.id']}>
                  <ListItemButton  onClick={() => props.selectTemplate(item)} > 
                    <ListItemText alignItems={'flex-end'} primary={item['templates.name']} />
                  </ListItemButton>
                  
                </ListItem>
              ))
            }
             
          </ul>
        </li>
      ))}
    </List>
  );
}

// styling and custom cell for working hours page 
// fix styles overlap
// from matierla ui api documentation
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 350
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },
  input: {
    width: 130,
    height: 40
  }
}));

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

export function DatesGrid(props)  {
  /*
  const [rows, setRows] = React.useState([ // starting data
    createHrsData("Monday", "9to5"),
    createHrsData("Tuesday", "9to5"),
    createHrsData("Wednesday", "9to5"),
    createHrsData("Thursday", "9to5"),
    createHrsData("Friday", "9to5"),
    createHrsData("Saturday", "9to5"),
    createHrsData("Sunday", "9to5")
  ]);
  */
  const [rows, setRows] = React.useState(props.WorkingHours);
  

  const [previous, setPrevious] = React.useState({});
  const classes = useStyles();

  const onToggleEditMode = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
    props.updateRows(newRows);
  };

  const onRevert = id => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };
 
  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table"> 
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left">Day</TableCell>
            <TableCell align="left">Operating Hrs</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                  <>
                    <IconButton
                      aria-label="done"
                      onClick={() => onToggleEditMode(row.id)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      aria-label="revert"
                      onClick={() => onRevert(row.id)}
                    >
                      <RevertIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    aria-label="delete"
                    onClick={() => onToggleEditMode(row.id)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell  >{row.day}</TableCell> 
              <CustomTableCell {...{ row, name: "time", onChange }} /> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}


const WorkColumns = [
  { field: 'day', headerName: 'Day', width: 200, headerAlign: 'center' },
  { field: 'time', headerName: 'Working Time', width: 255,  headerAlign: 'center', editable: true }
];

export function BasicEditingGrid(props) {
  const [rows, setRows] = React.useState(props.WorkingHours);
  console.log(props)
  const handleEditRowsModelChange = React.useCallback((model) => {
    //setEditRowsModel(model);
    //console.log(model);
    //props.updateRows(model);
    
    console.log( Object.keys(model) );

    if( Object.keys(model).length > 0 ){

      
      const id = Object.keys(model)[0];
      console.log( id )
      console.log(Object.keys(model).length)
      console.log(model[id].time.value)

      //https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
      // 1. Make a shallow copy of the items
      let items = [...props.WorkingHours];
      console.log(items);
      // 2. Make a shallow copy of the item you want to mutate
      let item = {...props.WorkingHours[id]};
      // 3. Replace the property you're intested in
      item.time = model[id].time.value;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      items[id] = item;
      // 5. Set the state to our new copy
      //setState({items});
      console.log(items);
      setRows(items)
      //props.setWorkingHours(items);
      props.updateRows(items);
    }

    
  }, []);
  return (
    <div style={{ height: 480 , width: "100%"}}>
      <DataGrid hideFooterPagination rows={rows} columns={WorkColumns} onEditRowsModelChange={handleEditRowsModelChange}/>
    </div>
  );
}



const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export function DataGridForWeek(props) {
   

  return (
    <div key={props.WorkingHours} style={{ height: 300 , width: "100%"}}>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={16}>
        <Grid item xs={10}>
          <Item>Day</Item>
        </Grid>
        <Grid item xs={16}> 
        </Grid>

        <Grid item xs={2}>
          <StyledInput 
           fullWidth
           name="Monday" 
           value = "Monday"
          />
        </Grid>
         
        <Grid item xs={2}>
          <StyledInput 
           fullWidth
           name="Tuesday" 
           value = "Tuesday"
          />
        </Grid>
        
        <Grid item xs={2.5}>
          <StyledInput 
           fullWidth
           name="Wednesday" 
           value = "Wednesday"
          /> 
        </Grid> 
        <Grid item xs={2}>
          <StyledInput 
           fullWidth
           name="Thursday" 
           value = "Thursday"
          />
        </Grid> 
        <Grid item xs={2}>
          <StyledInput 
           fullWidth
           name="Friday" 
           value = "Friday"
          />
        </Grid> 
        <Grid item xs={2}>
          <StyledInput 
           fullWidth
           name="Saturday" 
           value = "Saturday"
          />
        </Grid> 
        <Grid item xs={2}>
          <StyledInput 
           fullWidth
           name="Sunday" 
           value = "Sunday"
          />
        </Grid> 

        <Grid item xs={10}>
          <Item>Working Hours</Item>
        </Grid>
        <Grid item xs={16}> 
        </Grid>
        <Grid item xs={2}> 
          <StyledInput 
            name="Monday" 
            label="Monday"
            
            value= {props.WorkingHours ["Monday"] }
            onChange={(p) => {console.log(p.target.value); props.updateWorkingHrs(p.target.value,"Monday") } } 
          /> 
        </Grid> 
        <Grid item xs={2}> 
          <StyledInput 
          name="Tuesday" 
          label="Tuesday"
          /> 
        </Grid>
        <Grid item xs={2.5}> 
          <StyledInput 
          name="Wednesday" 
          label="Wednesday"
          /> 
        </Grid> 
        <Grid item xs={2}> 
          <StyledInput 
          name="Thursday" 
          label="Thursday"
          /> 
        </Grid> 
        <Grid item xs={2}> 
          <StyledInput 
          name="Friday" 
          label="Friday"
          /> 
        </Grid> 
        <Grid item xs={2}> 
          <StyledInput 
          name="Saturday" 
          label="Saturday"
          /> 
        </Grid> 
        <Grid item xs={2}> 
          <StyledInput 
          name="Sunday" 
          label="Sunday"
          /> 
        </Grid>  

      </Grid>
    </Box>
    </div>
  );
}

