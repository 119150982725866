import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import DashNavbar from "./DashNavbar";
import ListDetails from './listdetails';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import colorThm from "../colorTheme";
import CustomButton from '../customBtn'
import StyledInput from '../customTextField'

import { useHistory } from "react-router-dom"; 

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import APIpath from "../apiPath";
const axios = require('axios');
 

// component for the form so multiple can be generated
// next step 
// add in the handle button and pass in the user id, update color and take data from parent component
export default function RequestForm(props) {
  let history = useHistory();
  const report = props.report;

  const [email, setemail] = React.useState( "" );
  const [password, setpassword] = React.useState( "" );
  const [selected, setselected] = React.useState();
  const [edit, setedit] = React.useState(false); 

  const [accountsData, setaccountsData] = React.useState( props.history.location.state.accounts );

  const [email_services, setemail_services] = React.useState([]);
  const [selected_email_service, setselected_email_service] = React.useState(null);

  console.log(accountsData)
  
  const serviceChange = (event) => {
    setselected_email_service(event.target.value);
  };

  React.useEffect(() => { // use effect : on render, get all service providers 

    console.log('get file ');
    //window.sessionStorage.setItem('accesstoken',  props.history.location.state ? props.history.location.state.accesstoken : "");
  
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/service_providers/index.json", 
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
    console.log("service_providers");
      console.log(res);
      if(res.data.paginate){
        console.log(res.data.paginate.data);
        setemail_services(res.data.paginate.data);
      }
      
    });
    
  }, []);

  const Reset  = () =>{
    setemail("")
    setpassword("") 
    setselected_email_service( null );
    setedit(false);
  }
  
  const NewAccount = ( ) => { // handle adding a new account , add check for error messages
    console.log("data -- select email")
      
    
    var bodyFormData = new FormData(); 
    console.log(selected_email_service)
    bodyFormData.append('data[accounts][name]', email);
    bodyFormData.append('data[accounts][email]', email);
    bodyFormData.append('data[accounts][password]', password);
    bodyFormData.append('data[accounts][service_provider_id]', selected_email_service);
    bodyFormData.append('data[accounts][assistant_name]', "Eve");
    

    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/accounts/add.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log(res);
        console.log(res.data);
        //PHPSESSID=sbsqqpildvnccpfkgpji7c6l2i
        const message = res.data.message; // chcek len of errors 

        axios({
          method: "post",
          url: "https://evemail.fiyge.com/evemail/accounts/index.json", 
          headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
        }).then(res => {
          console.log("service_providers");
            console.log(res);
            if(res.data.paginate){
              console.log(res.data.paginate.data);
              setaccountsData(res.data.paginate.data);
            }
            
        });
  
    });

  };

  const EditAccount = () => { // save account edits to model , get id from selected 
    console.log("data -- edi email")

    var bodyFormData = new FormData();
    //selected for id ::
    console.log(selected);
    console.log(selected['accounts.id']);
    bodyFormData.append('data[accounts][id]', selected['accounts.id'] ); 
    bodyFormData.append('data[accounts][email]', email );
    bodyFormData.append('data[accounts][password]', password );
    console.log(bodyFormData);
    
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/accounts/edit.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log(res);
        console.log(res.data);
        //PHPSESSID=sbsqqpildvnccpfkgpji7c6l2i
        const message = res.data.message; // chcek len of errors 
 
        //console.log(accesstoken); 
        //history.push("/UserPage",{ accesstoken:accesstoken });
        axios({
          method: "post",
          url: "https://evemail.fiyge.com/evemail/accounts/index.json", 
          headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
        }).then(res => {
            console.log("service_providers");
            console.log(res);
            if(res.data.paginate){
              console.log("res.data.paginate.data");
              console.log(res.data.paginate.data);
              setaccountsData(res.data.paginate.data);
            }
            
        });
  
  
    });
    
      
  };

  const handleEmailSelect = (data) => { // selecting row function update current and set to edit 
    console.log(data)
    if( data['accounts.email'] != null || data['accounts.password'] != null){
      setemail(data['accounts.email']);
      setpassword(data['accounts.password']); 
      console.log(email_services)
      if(data['Service Provider'] != null){
        var index = email_services.map(function(e) { return e['service_providers.name']; }).indexOf(data['Service Provider']);
        console.log(index)

        var data_serviceID = email_services[index]
        console.log(data_serviceID)
        setselected_email_service( data_serviceID['service_providers.id'] )
      }
      
      //selected_email_service()
      
    }else{
      setemail("");
      setpassword("");
      
    }
    setedit(true); // add reset button 
    setselected(data);


  };
  
  const handleSubmit = (event) => { // submit function, depending on edit or new different functions called
    event.preventDefault();
    console.log("handleSubmit"); 
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
      id: data.get('id'),
    });
        
    // axios and add to list if passed and not already done

    if(edit == false){
      NewAccount();
      setaccountsData([...accountsData, {'accounts.email':email,'accounts.password':password, selected_email_service} ]);

    }else{
      EditAccount();
    }

    setemail("")
    setpassword("") 
    setselected_email_service( null );
    setedit(false);

    
  };

  //  list of emails and form for adding new, clicking on one cahnges to edit
  // need a reset button 
  return ( 
    <div>
    <CssBaseline />
      <DashNavbar title = "EveMail" to = "/UserPage" />

      <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
        >
          Add email
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit}  sx={{padding: 5,m: 4, p: 4}}> 
          <Grid container alignItems="center" justifyContent="center" spacing={3}> 
            
              <Grid item xs={4}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email" 
                  value= {email}
                  onChange={(p) => {setemail(p.target.value)} }  
                />
              </Grid>
              <Grid item xs={4}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="password"
                  label="password" 
                  type="password"
                  value= {password}
                  onChange={(p) => {setpassword(p.target.value)} }  
                />
              </Grid> 
              <Grid item xs={4}>
                
                <Select
                  fullWidth
                  labelId="select-label"
                  id="simple-select"
                  value={selected_email_service}
                  label="Email Service"
                  onChange={serviceChange}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px"
                  }} 
                >
                  { 
                    email_services.map((name) => (
                      <MenuItem value={name['service_providers.id']}>{name['service_providers.name']}</MenuItem>
                    ))
                  }
                  
                </Select>
              </Grid>   
              <Grid item xs={12}> 
                <CustomButton variant="contained" onClick={Reset} >  
                  Reset
                </CustomButton> 
                <CustomButton variant="contained"  type="submit" >
                  {edit ?  "Edit Email" : "Add Email" } 
                </CustomButton>  
                
              </Grid>  
               

              <Grid item xs={10}>
                <ListDetails userData = {accountsData} handleEmailSelect={handleEmailSelect}/> 
              </Grid>  
  
          </Grid>
        </Box>

        

    </div>
  );
}

