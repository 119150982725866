const theme = { 
    roundness: 5,
    colors: { 
      primary: "#171e76",
      secondary:"#b8b8b8",
      accent: "#f1c40f",
      backgroundColor: "#c3ccfb",
      favorite: "#BADA55",
      cancelButton: "#a4c639",
      iconColor: "#808080",
      Palette_One:"#FFFFFF",
      Palette_Two:"#e0e0e0",
      
      Palette_Three:"#203283",
      Palette_Four:"#D17B0F",
      Palette_Five:"#F96900",
  
      SelectedBtn: '#203283',
      UnSelectedBtn: '#9ACD32'
      
    },
    
};
  
export default theme;