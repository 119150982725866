import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useHistory } from "react-router-dom";

import colorThm from "../colorTheme";
import CustomButton from '../customBtn'

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import GavelIcon from '@mui/icons-material/Gavel';

/*
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={1} rounded {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
*/



const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.3rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = React.useState('panel1');

  let history = useHistory();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div >

      {
        (props.data).map( (items) => (
        
        <Accordion  expanded={expanded === items['accounts.id']} onChange={handleChange(items['accounts.id'])}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header"> 
            <Typography
              component="h6"
              variant="h5"
              align="center"
              color="text.primary"
              >  
              {items['accounts.email']} 
            </Typography>
            
          </AccordionSummary>
          <AccordionDetails >
          {console.log(items)}
          {console.log(items['accounts.email'])}
            <Typography>
              <Stack 
                sx={{ pt: 1 }}
                spacing={2}
                justifyContent="center"
              >

                <Typography 
                  component="h2"
                  variant="h5"
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  Account Options 
                </Typography> 
                <Stack
                  direction="row"
                  sx={{ pt: 2 }}
                  spacing={10}
                  justifyContent="center"
                >
                  <CustomButton variant="contained" onClick={ () => history.push("/UserSchedule",{items})} startIcon={<EventAvailableIcon/>}>Scheduling</CustomButton>
                  <CustomButton variant="contained" onClick={ () => history.push("/UserRules",{items})} startIcon={<GavelIcon/>}>User Rules</CustomButton>
                  
                  <Button variant="contained" align='right'onClick={ () => props.runBot(items) } style = {{backgroundColor: colorThm.colors.primary}}>
                    manual run
                  </Button>
                  
                  <CustomButton variant="contained" onClick={ () => history.push("/TemplatesPage",{items})} endIcon={<ArticleIcon/>}>Templates </CustomButton>
                  <CustomButton variant="contained" onClick={ () => history.push("/InformationPage",{items})} endIcon={<InfoIcon/>}>Information </CustomButton>
                </Stack> 
              </Stack> 
            </Typography>
          </AccordionDetails>
        </Accordion>

        

        )

        )
       }
 
 
     
    </div>
  );
}
