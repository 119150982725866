import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { alpha, styled } from '@mui/material/styles';
import { useState, useEffect  } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";

import DashNavbar from "./DashNavbar";
import { RulesList } from './listdetails';
import colorThm from "../colorTheme";
import StyledInput from "../customTextField";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import CustomButton from '../customBtn' 


const axios = require('axios');

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-input': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});


const dummyEmails = [
  {"email": "a@gmail.com"},{"email": "b@gmail.com"},{"email": "c@gmail.com"}
]


// page for display and setting automation rules for the user 
export default function UserPage(props) {
 /// var userData = props.history.location.state.userData;
  //console.log(userData)
  let history = useHistory();
  const [ruleOptions, setruleOptions] = React.useState([]);
  const [accountRules, setaccountRules] = React.useState([]);

  const [selectedRules, setselectedRules] = React.useState('');
  const [content, setcontent] = React.useState('');  
  const [result, setresult] = React.useState(''); 
  //items
  

  useEffect(() => { // get user set rules, no id
    console.log(props.location.state)
    console.log(props.location.state.items['accounts.id'])
    console.log('get file ');
    var query={};
    query['where']=[{"rules.template_application":"2"}];
    console.log(query);
    console.log(encodeURIComponent(JSON.stringify(query)));
    axios({
      method: "get",
      url: "https://evemail.fiyge.com/evemail/rules/index.json?q="+encodeURIComponent(JSON.stringify(query)), 
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
      console.log("rules");
      console.log(res);
      if(res.data.paginate){
        console.log(res.data.paginate.data);
        setruleOptions(res.data.paginate.data);
        
      }  
    });

    var rulesquery={};
    rulesquery['where']=[{"account_rules.account_id":props.location.state.items['accounts.id']} ,{"rules.template_application":"2"} ];
    //rulesquery['where']=[{"account_rules.account_id":props.location.state.items['accounts.id']},{ "account_rules.template_id": "0" } ];
    //,{ "account_rules.template_id": "0" }
    console.log(rulesquery);

    // "https://evemail.fiyge.com/evemail/rules/index.json?q="+encodeURIComponent(JSON.stringify(query)), 
    axios({
      method: "get",
      url: "https://evemail.fiyge.com/evemail/account_rules/index.json?q="+encodeURIComponent(JSON.stringify(rulesquery)),
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
       
    }).then(res => {
      console.log("account_rules"); 
      if(res.data.paginate){
        console.log(res.data.paginate.data); 
        setaccountRules(res.data.paginate.data);
      }
      
    });
        
  }, []);

  const submitBtn = () => {
    console.log("submit");
    console.log(selectedRules)
    console.log(content)
    console.log(result)
    if( result != null && selectedRules != null && content != null){
      
      console.log(props.location.state.items['accounts.id'])

      var bodyFormData = new FormData();  
      bodyFormData.append('data[account_rules][result]', result);
      bodyFormData.append('data[account_rules][trigger_condition]', content);
      bodyFormData.append('data[account_rules][rule_id]', selectedRules['rules.id']);
      bodyFormData.append('data[account_rules][account_id]', props.location.state.items['accounts.id'] );
      
      // axios call to submit and set array 

      axios({
        method: "post",
        url: "https://evemail.fiyge.com/evemail/account_rules/add.json", 
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => {
        console.log("account_rules"); 
        if(res.data.paginate){
          console.log(res.data.paginate.data); 
          setaccountRules(res.data.paginate.data);
        }
        
      });

      setselectedRules('');
      setcontent('');
      setresult('');
    }
    
    /*
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/account_rules/add.json", 
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
      console.log("account_rules"); 
      if(res.data.paginate){
        console.log(res.data.paginate.data); 
        
      }
      
    });
    */
    

  };
 
  const actionChange = (event) => {
    console.log(event.target.value)
    setselectedRules(event.target.value);
  };

  function deleteRule (data) {
    console.log(data);
    //https://bundle-developmentv509.com/crm/opportunities/delete.json/is_confirm:1?id=5
    //https://evemail.fiyge.com/evemail/account_rules/delete.json/is_confirm:1?id=
     
    var rulesquery={};
    rulesquery['where']=[{"account_rules.account_id":props.location.state.items['accounts.id']}  ];
    //rulesquery['where']=[{"account_rules.account_id":props.location.state.items['accounts.id']},{ "account_rules.template_id": "0" } ];
    //,{ "account_rules.template_id": "0" }
    console.log(rulesquery);

    axios({
      method: "get",
      url: "https://evemail.fiyge.com/evemail/account_rules/delete.json/is_confirm:1?id="+ (data['account_rules.id']),
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
       
    }).then(res => {
      console.log("account_rules"); 
      if(res.data.paginate){
        console.log(res.data.paginate.data); 
         

      }
      
    });  

  };
  
  
  console.log(selectedRules);

  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "EveMail" to = "/UserPage" />
      <main> 
        <Box
          sx={{ 
            pt: 8,
            pb: 6
          }}
        >
          <Container  >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              User Rules
            </Typography>

            <Grid container spacing={2}> 
              <Grid item xs={12}>
                <Typography
                  component="h5"
                  variant="h5"
                  align="left"
                  color="text.primary"
                  gutterBottom
                >
                  Add New Rules
                </Typography> 
              </Grid>
              <Grid item xs={2}> 
              
                <Select
                  fullWidth
                  labelId="Action-label"
                  id="Action-select"
                  value={selectedRules}
                  label="Email Service"
                  onChange={actionChange}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px"
                  }} 
                >               
                  { //selectedRules, setselectedRules
                    ruleOptions.map((name) => (  
                      
                      <MenuItem value= { name} title="testeeee">{ 
                           name['rules.name']
                          }
                      </MenuItem>
                      

                    ))
                  }
                </Select>

              </Grid>
              <Grid item xs={6}>
              <Tooltip title="Details to trigger the rule">
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="Content"
                  label="Content" 
                  value= {content}  
                  onChange={(p) => { 
                    setcontent(p.target.value)
                  } }    
                />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="Address To send the results">
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="Result"
                  label="Result"
                  value=  {result} 
                  onChange={(p) => {
                    setresult(p.target.value)
                  } }    
                />
                 </Tooltip  >
              </Grid>
              <Grid item xs={12}> 
                <CustomButton variant="contained" onClick={ () =>  submitBtn()} style = {{backgroundColor: colorThm.colors.primary}} >
                   Submit Rule
                </CustomButton>
              </Grid>

              <Grid item xs={12}> 
               <RulesList accountRules={accountRules} deleteRule={deleteRule} />
              </Grid>
            </Grid>  
          </Container>
        </Box> 
      </main>
      
    </div>
  );
}

//direction="row"
/*
 
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Keep up to date with your medical history 
                </Typography>
*/