import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import DashNavbar from "./DashNavbar";
import ListDetails from './listdetails';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import colorThm from "../colorTheme";
import CustomButton from '../customBtn';
import StyledInput from '../customTextField';

import { useHistory } from "react-router-dom"; 


import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect  } from 'react';

import APIpath from "../apiPath";
import {DataGridForWeek,DatesGrid,BasicEditingGrid } from './listdetails';
import { DataGrid } from '@mui/x-data-grid';  


import TimePicker from '@mui/lab/TimePicker';

const axios = require('axios');

// component for the form so multiple can be generated
// next step 
// add in the handle CustomButton and pass in the user id, update color and take data from parent component
export default function UserSchedule(props) {
  let history = useHistory();
  const report = props.report;
 
  const [selectedDay, setselectedDay] = React.useState(null);
  const [selectedView, setselectedView] = React.useState(null); // selected access view
  const [selectedPlatforms, setPlatforms] = React.useState(null); // selected meeting platform

  const [Days, setDays] = React.useState(["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]);
  const [scheduleView, setscheduleView] = React.useState(["Day","Week","Month"]);
  const [MeetingPlatforms, setMeetingPlatforms] = React.useState(["Google","Zoom","Teams"]);

 

  const [startTime, setstartTime] = useState('09:00');
  const [endTime, setendTime] = useState('17:00');

  const [ScheduleTime, setScheduleTime] = useState([]);

  function UpdateUserDetails()  { // call back function , from templates table  

    // axios --> pull user list from rules 
    var bodyFormData = new FormData();
    bodyFormData.append('data[accounts][id]',props.location.state.items['accounts.id']  );
    bodyFormData.append('data[accounts][schedule_access]',  selectedView);
    bodyFormData.append('data[accounts][meeting_platform]',  selectedPlatforms);
     
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/accounts/edit.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log(res);
        console.log(res.data);   
    });

  }

 
 
  useEffect(() => { // pull all faq releated to user and organize 

      console.log('get file ');
      console.log( props );
      var bodyFormData = new FormData();
      // axios --> pull user list from rules  
      bodyFormData.append('data[accounts][id]',props.location.state.items['accounts.id']  );
      
      var query={};
      //
      //query["where"] = [{"account_id":"4"} ]; //, {"account_id":props.location.state.items['accounts.id']}
      query["where"] = [{"accounts.id":props.location.state.items['accounts.id']}  ]; // 
      const queryURI = encodeURIComponent(JSON.stringify( query ));
      console.log(query)
      axios({
        method: "post",
        url: "https://evemail.fiyge.com/evemail/accounts/index.json?q="+queryURI, 
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
      }).then(res => {
          console.log(res);
          console.log(res.data); 
          //setscheduleView
          //setMeetingPlatforms
          if(res.data.paginate){
            console.log(res.data.paginate.data[0]);  
            setselectedView(res.data.paginate.data[0]['accounts.schedule_access'])
            setPlatforms(res.data.paginate.data[0]['accounts.meeting_platform'])
          }
      });
 
  }, []);
 
  const SelectDay = (event) => {
    setselectedDay(event.target.value);
  };

  const SelectView = (event) => {
    setselectedView(event.target.value);
  };

  const SelectPlatform = (event) => {
    setPlatforms(event.target.value);
  };

  return ( 
    <div> 
      <CssBaseline />
        <DashNavbar title = "EveMail" to = "/UserPage" />
      <Container  >
        
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Account Schedule 
        </Typography>

        <Grid container alignItems="center" justifyContent="center" spacing={3}> 

          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Available for a meeting
            </Typography> 
          </Grid> 

          <Grid item xs={6}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Day
            </Typography> 
          </Grid> 

          

          <Grid item xs={6}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Period
            </Typography> 
          </Grid> 
          <Grid item xs={4}>
                
                <Select
                  fullWidth
                  labelId="select-label"
                  id="simple-select" 
                  value={selectedDay}
                  label="Day"
                  onChange={SelectDay}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px"
                  }} 
                >

                  { 
                    Days.map((name) => (
                      <MenuItem value={name }>{name }</MenuItem>
                    ))
                  }
                   
                </Select>
            </Grid> 

            <Grid item xs={3}>  
              <StyledInput
                fullWidth
                variant="filled"
                id="time"
                label="Start Time"
                type="time"
                defaultValue={startTime}
                InputLabelProps={{
                shrink: true,
                }}
                inputProps={{
                step: 300, // 5 min
                }}
                sx={{ m:1}}
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px"
                }} 
                onChange={(p) => {setstartTime(p.target.value)} } 
              /> 
            </Grid> 
            <Grid item xs={3}> 
            <StyledInput
              variant="filled"
                fullWidth
                id="time"
                label="End Time"
                type="time"
                defaultValue={endTime}
                InputLabelProps={{
                shrink: true,
                }}
                inputProps={{
                step: 300, // 5 min
                }}
                sx={{ m:1 }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px"
                }} 
                onChange={(p) => {setendTime(p.target.value)} } 
              />
            </Grid> 

          <Grid item xs={6}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Schedule Access
            </Typography> 
          </Grid> 
          <Grid item xs={4}>
                
                <Select
                  fullWidth
                  labelId="select-label"
                  id="simple-select" 
                  
                  
                  value={selectedView}
                  label="View"
                  onChange={SelectView}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px"
                  }} 
                >
                   { 
                    scheduleView.map((name) => (
                      <MenuItem value={name }>{name }</MenuItem>
                    ))
                  }
                </Select>   
            </Grid> 

          <Grid item xs={6}>
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Meeting Platform
            </Typography> 
          </Grid> 
          <Grid item xs={4}>
                
                <Select
                  fullWidth
                  labelId="select-label"
                  id="simple-select" 
                   
                  value={selectedPlatforms}
                  label="Meeting_Platforms"
                  onChange={SelectPlatform} 
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px"
                  }}
                >

                  { 
                    MeetingPlatforms.map((name) => (
                      <MenuItem value={name }>{name }</MenuItem>
                    ))
                  }
                   
                </Select>
            </Grid>  
           
          <Grid item xs={12}>
            <CustomButton onClick = {() => {console.log("WorkingHours"); console.log(startTime);console.log(endTime);UpdateUserDetails();}} variant="contained" style = {{backgroundColor: colorThm.colors.primary}}  >
              Submit Updates 
            </CustomButton>
          </Grid>
    
        </Grid>
      </Container> 
    

    </div>
  );
}

/*
<Grid item   xs={6}> 
                <BasicEditingGrid WorkingHours={WorkingHours} updateRows={updateRows} />
              </Grid> 
*/
