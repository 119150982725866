import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect, useContext, createContext } from 'react'

import Navbar from "../layout/Navbar";

import { useCallback } from 'react' 

import { useHistory } from "react-router-dom"; 
import APIpath from "../apiPath";

import colorThm from "../colorTheme";
import StyledInput from '../customTextField';

import { FacebookLoginButton, GoogleLoginButton  } from "react-social-login-buttons";

const axios = require('axios');
const theme = createTheme();

// user sign up using a material ui form
export default function SignUp() {
  let history = useHistory();
  const [pass,setpass]=useState(true);
  // function for when the form is submitted
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //const auth = getAuth();
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    // add mediation here to set pass

    // call to sign up
    var bodyFormData = new FormData();
    //'admin@evemail.fiyge.com'
    bodyFormData.append('data[users][user_name]',      data.get('email') ); 
    bodyFormData.append('data[users][first_name]',      data.get('firstName') );
    bodyFormData.append('data[users][last_name]',  data.get('lastName'));
    bodyFormData.append('data[users][organization_id]',  '2');
    bodyFormData.append('data[users][is_active]',  '1');
    bodyFormData.append('data[users][is_license_agreement_accepted]',  '1');
       
    bodyFormData.append('data[users][email_addresses][0][email]',  data.get('email'));


    bodyFormData.append('data[users][roles_users][0][role_id]',  '61b0ee87-4404-4d0c-9f22-465aac69033c'); // update 

    bodyFormData.append('data[users][iam][0][time_zone]',  'America/Toronto');
    bodyFormData.append('data[users][iam][0][hour_format]',  '24');
    bodyFormData.append('data[users][iam][0][theme]',  '3_panel_field_view/redmond');
    bodyFormData.append('data[users][iam][0][locale]', 'en_US');
    bodyFormData.append('data[users][iam][0][user_password]',  data.get('password'));
    bodyFormData.append('data[users][iam][0][confirm_password]',  data.get('password')); 

    var accesstoken = ''

    //axios.post(`https://evemail.fiyge.com/access_controls/users/login.json`, { user })
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/access_controls/users/signup.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
        console.log(res);
        console.log(res.data);
        //PHPSESSID=sbsqqpildvnccpfkgpji7c6l2i
        const message = res.data.message; // chcek len of errors
        if(res.data.errors.length == 0 ){
          accesstoken = res.data.access_token;
      
          //console.log(accesstoken); 
          history.push("/");
        }
        
  
    });
 

  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: colorThm.colors.primary }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            User Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  autoComplete="given-name"
                  name="firstName"
                  required
                  
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledInput   
                  variant="filled"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
                
              </Grid>
              <Grid item xs={12}>
                <StyledInput   
                  variant="filled"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid> 
              <Grid item xs={12}>
                <StyledInput   
                  variant="filled"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained" 
              sx={{ mt: 3, mb: 2 }}
              style = {{backgroundColor:colorThm.colors.primary}} 
            >
              Sign Up
            </Button>
            { !pass && 
              <Typography component="h6" variant="h6" style={{ color: 'red' }}>
                email or password not good enough?
              </Typography>
            }

            <Grid container>
              <Grid item xs={12}>
                <Link href="https://evemail.fiyge.com/access_controls/users/social_signup?provider=Google&amp;role_id=ebd2b202-efc0-11e3-8f90-12313d0872e0&amp;organization_id=2">
                  <GoogleLoginButton >
                    <span> Sign Up with Google</span>
                  </GoogleLoginButton >
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href= "https://evemail.fiyge.com/access_controls/users/social_signup?provider=Facebook&amp;role_id=ebd2b202-efc0-11e3-8f90-12313d0872e0&amp;organization_id=2">
                  <FacebookLoginButton >
                    <span>  Sign Up with Facebook </span>
                  </FacebookLoginButton>
                </Link>
              </Grid> 
            </Grid> 
            
            <Grid container justifyContent="flex-center">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>

            
          </Box>
        </Box> 
      </Container>
    </ThemeProvider>
  );
}