import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { alpha, styled } from '@mui/material/styles';
import { useState, useEffect  } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";

import DashNavbar from "./DashNavbar";
import EmailList from "./emailList";
import TemplateCard from './templateCards';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import colorThm from "../colorTheme";
import CustomButton from '../customBtn';
import StyledInput from '../customTextField'; 
import { SubheaderList,TemplatesList } from './listdetails';

const axios = require('axios');


const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-input': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});


const dummyEmails = [
  {"email": "a@gmail.com"},{"email": "b@gmail.com"},{"email": "c@gmail.com"}
]


// patients page for them to them to select a option on how to process
// 3 btns for current features, user transactions history add later
// next: add in pdf viewer for user files 
export default function UserPage(props) {
 /// var userData = props.history.location.state.userData;
  //console.log(userData)
  let history = useHistory();
  const [assistantName, setassistantName] = React.useState( ('Eve'));
  const [selectedTemplate, setselectedTemplate] = React.useState({'templates.name': 'Please Select Template from List'});

  const [inUseTemplates, setinUseTemplates] = React.useState([]);

  const [templates, settemplates] = React.useState( ([]));
  const [headers,setHeaders] = React.useState([]); 

  function selectTemplate( data )  { // call back function , from templates table 
    console.log('selectedTemplate');
    console.log(data);
    setselectedTemplate(data);
  }

  function selectTemplateForUse()  { // call back function , from templates table 
    console.log('select Template to use');
    console.log(props);
    console.log(props.location.state.items);
    console.log(props.location.state.items['accounts.id']);
    console.log(selectedTemplate);

    // axios --> pull user list from rules 
    var bodyFormData = new FormData();
    bodyFormData.append('data[account_rules][result]', selectedTemplate['templates.template_layout']);
    bodyFormData.append('data[account_rules][account_id]',  props.location.state.items['accounts.id']);
    bodyFormData.append('data[account_rules][template_id]', selectedTemplate['templates.id']);
    //bodyFormData.append('data[account_rules][rule_id]', 'admin@evemail.fiyge.com');
    bodyFormData.append('data[account_rules][trigger_condition]', selectedTemplate['templates.respond_case']);

    console.log(window.sessionStorage.getItem('userUID'))

    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/account_rules/add.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log(res);
        console.log(res.data); 
        var query={};
        //{ "templates_id >": 0 }
        //query["where"] = [{"account_id":"4"} ]; //, {"account_id":props.location.state.items['accounts.id']}
        //query["where"] = [{"account_id":"4"} ]; //, {"account_id":props.location.state.items['accounts.id']}
        setselectedTemplate({})
           
          
         
    });

  }

  function selectBotName()  { // call back function , from templates table  

    // axios --> pull user list from rules 
    var bodyFormData = new FormData();
    bodyFormData.append('data[accounts][id]',props.location.state.items['accounts.id']  );
    bodyFormData.append('data[accounts][assistant_name]',  assistantName);
     
    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/account_rules/edit.json",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log(res);
        console.log(res.data);   
    });

  }

  // pull in templates, generate 
  useEffect(() => {
   
    console.log('get file ');
    //downloadFile("/Medical Report Form Sample.pdf",setpdfurl); 
    //https://firebasestorage.googleapis.com/v0/b/blocksure-8c41f.appspot.com/o/Medical%20Report%20Form%20Sample.pdf?alt=media&token=11649600-6654-4557-950e-505f1b06b471
    

    var userquery= {};
    userquery["where"] = [{"accounts.id":props.location.state.items['accounts.id']} ];


    const userqueryURI = encodeURIComponent(JSON.stringify( userquery ));
    console.log(userqueryURI)

    axios({
      method: "get",
      url: "https://evemail.fiyge.com/evemail/accounts/index.json?q="+userqueryURI, 
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => { 
      console.log("res.data.paginate");
      console.log(props.location.state.items['accounts.id']); 
      console.log(res.data );
      if(res.data.paginate){
        console.log(res.data.paginate.data);
        console.log(res.data.paginate.data[0]);
        console.log(res.data.paginate.data[0]['accounts.assistant_name']);

        //setinUseTemplates(res.data.paginate.data);
        setassistantName( res.data.paginate.data[0]['accounts.assistant_name'] )
      }
      
    });

    axios({
      method: "post",
      url: "https://evemail.fiyge.com/evemail/templates/index.json",
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => {
        console.log(res);
        console.log(res.data); 

        if(res.data.paginate){
          console.log(res.data.paginate.data);
          //settemplates(res.data.paginate.data);
          settemplates(res.data.paginate.data);        
          setHeaders(res.data.paginate.data.map(item => item['templates.respond_case']).filter((value, index, self) => self.indexOf(value) === index))
        
        
          axios({
            method: "get",
            url: "https://evemail.fiyge.com/evemail/account_rules/index.json?q="+queryURI, 
            headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
          }).then(res => { 
            console.log("res.data.paginate");
            console.log(props.location.state.items['accounts.id']);
            console.log(res.data);
            if(res.data.paginate){
              console.log(res.data.paginate.data);
              setinUseTemplates(res.data.paginate.data);
            }
            
          });
         
        }   

        
    });


    // templates in use 
    console.log('get templates ');
   
    var query={};
    //
    //query["where"] = [{"account_id":"4"} ]; //, {"account_id":props.location.state.items['accounts.id']}
    query["where"] = [{"account_id":props.location.state.items['accounts.id']},{ "template_id <>": "0" } ]; //, {"account_id":props.location.state.items['accounts.id']}


    const queryURI = encodeURIComponent(JSON.stringify( query ));
    console.log(queryURI)
    axios({
      method: "get",
      url: "https://evemail.fiyge.com/evemail/account_rules/index.json?q="+queryURI, 
      headers: { "Content-Type": "multipart/form-data" , 'Authorization': 'Bearer ' + window.sessionStorage.getItem('userUID')},
    }).then(res => { 
      //console.log("res.data.paginate");
      //console.log(props.location.state.items['accounts.id']);
      //console.log(res.data);
      if(res.data.paginate){
        console.log(res.data.paginate.data);
        setinUseTemplates(res.data.paginate.data  );
      }
      
    });

    
  }, []);  

  console.log(templates);
  console.log(headers);
 

  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "EveMail" to = "/UserPage" />
      <main>
        <Box
          sx={{
            pt: 8,
            pb: 6
          }}
        >
          <Container  >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Templates
            </Typography>
               
            
            <Grid container alignItems="center" justifyContent="center" spacing={3} columns={18}> 
              
              <Grid item xs={6}>
                <Typography
                  component="h5"
                  variant="h5"
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  Bot Name:
                </Typography> 
              </Grid>

              <Grid item xs={6}>
                <StyledInput  
                  fullWidth
                  variant="filled"
                  name="Assistants Name"
                  label="AssistantsName"
                  value= { assistantName }
                  onChange={(p) => {setassistantName(p.target.value)} } 
                  
                />
              </Grid>
              {
                //value= {mission }
                //onChange={(p) => {setmission(p.target.value)} } 
                //onClick={ () => handleSubmitProduct() }
              }
              <Grid item xs={4}>
                <CustomButton variant="contained" onClick={ () => selectBotName() } style = {{backgroundColor: colorThm.colors.primary}} >
                  Save
                </CustomButton>
              </Grid>
              
              <Grid item xs={3}>
                <Typography
                  component="h5"
                  variant="h5"
                  align="Left"
                  color="text.primary"
                  gutterBottom
                >
                  Templates List
                </Typography> 
                
                <SubheaderList templates={templates} headers={headers} selectTemplate={selectTemplate}/>
              </Grid>

              <Grid item xs={5}>
                               
                <TemplateCard selectedTemp = {selectedTemplate} /> 
                
                <CustomButton variant="contained" onClick={ () => selectTemplateForUse() } type="submit" >
                  Deploy Template
                </CustomButton>  
              </Grid> 

              <Grid item xs={10}>
                <Typography
                  component="h5"
                  variant="h5"
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  Deployed Templates
                </Typography> 
                {
                  console.log(inUseTemplates)
                }
                <span key={inUseTemplates}> 
                  <TemplatesList templates={templates} templateDetails = {inUseTemplates}/>
                </span>
              </Grid> 
              
              
            </Grid>
          </Container>
        </Box> 
      </main>
      
    </div>
  );
}

//direction="row"
/*
  <Typography
    component="h5"
    variant="h5"
    align="center"
    color="text.primary"
    gutterBottom
  >
    Selected Template
  </Typography> 
*/
/*
 
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Keep up to date with your medical history 
                </Typography>
*/